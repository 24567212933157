// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-articles-eventador-index-js": () => import("./../../../src/articles/eventador/index.js" /* webpackChunkName: "component---src-articles-eventador-index-js" */),
  "component---src-articles-funding-societies-index-js": () => import("./../../../src/articles/funding-societies/index.js" /* webpackChunkName: "component---src-articles-funding-societies-index-js" */),
  "component---src-articles-ramp-index-js": () => import("./../../../src/articles/ramp/index.js" /* webpackChunkName: "component---src-articles-ramp-index-js" */),
  "component---src-articles-stoovo-index-js": () => import("./../../../src/articles/stoovo/index.js" /* webpackChunkName: "component---src-articles-stoovo-index-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-side-projects-js": () => import("./../../../src/pages/side-projects.js" /* webpackChunkName: "component---src-pages-side-projects-js" */)
}

